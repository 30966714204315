<template>
  <div class="box">
    <div class="title">
      <span>消息模板详情</span>
      <span>
        <el-button size="small" @click="back">返回</el-button>
        <el-button size="small" type="primary" @click="edit">编辑</el-button>
      </span>
    </div>
    <div v-loading="loading" class="content">
      <div class="title-top"><span class="line"></span> 基本信息</div>
      <div class="desc">
        <div class="name">模板类型：</div>
        <div class="val">{{ data.categoryName }}</div>
      </div>
      <div class="desc">
        <div class="name">模板名称：</div>
        <div class="val">{{ data.templateName }}</div>
      </div>
      <div class="desc">
        <div class="name">模板内容：</div>
        <div class="val">{{ data.templateContent }}</div>
      </div>
      <div class="desc">
        <div class="name">消息类型：</div>
        <div class="val">{{ data.messageTypeName }}</div>
      </div>
      <div class="desc">
        <div class="name">服务场景描述：</div>
        <div class="val">{{ data.businessScene }}</div>
      </div>
      <div class="desc">
        <div class="name">推送方式：</div>
        <div class="val">{{ data.channelIdName }}</div>
      </div>
      <div v-if="data.channelId == 1 || data.channelId == 2">
        <div class="title-top"><span class="line"></span>渠道审核信息</div>
        <el-table ref="multipleTable" :data="tableData" border style="width: 100%">
          <el-table-column prop="date" label="序号" width="180"> </el-table-column>
          <el-table-column prop="subChannelName" label="推送通道"> </el-table-column>
          <el-table-column prop="subChannelTemplateId" label="推送通道模板ID"> </el-table-column>
          <el-table-column prop="name" label="关键词顺序组合"> </el-table-column>
          <el-table-column prop="auditStatus" label="审核状态"> </el-table-column>
          <el-table-column prop="auditTime" label="审核时间"> </el-table-column>
          <el-table-column prop="auditFailureReason" label="失败原因"> </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { detailByIds } from '@/api'

export default {
  props: {
    row: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      tableData: [],
      data: {},
      loading: false,
    }
  },
  mounted() {
    this.detailByIds()
  },
  methods: {
    detailByIds() {
      this.loading = true
      detailByIds({
        data: {
          templateIds: [this.row.templateId],
        },
      }).then((res) => {
        this.loading = false
        this.data = { ...res.data[0] }
        if (this.data.messageTemplateChannelResDto) {
          this.tableData = this.data.messageTemplateChannelResDto
        }
      })
    },
    back() {
      this.$emit('back')
    },
    edit() {
      this.$emit('edit', this.row)
    },
  },
}
</script>

<style lang="less" scoped>
.box {
  border: 1px solid #eee;
  background-color: #fff;
}
.content {
  padding: 10px 50px;
  .title-top {
    display: flex;
    align-items: center;
    font-weight: bold;
    line-height: 50px;
    .line {
      display: inline-block;
      width: 4px;
      height: 20px;
      background-color: #0079fe;
      margin-right: 6px;
    }
  }
  .desc {
    line-height: 50px;
    padding-left: 20px;
    font-size: 15px;
    color: #333;
    display: flex;
    .val {
      color: #666;
    }
  }
}
.title {
  padding: 10px 50px;
  font-weight: bold;
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
