<template>
  <div class="main treeWrap">
    <div v-if="!isAdd && !showDetail" class="wrap">
      <div class="left">
        <div class="title">短信模板类型</div>
        <el-tree
          ref="tree"
          v-loading="treeLoading"
          :data="data"
          :props="defaultProps"
          node-key="categoryId"
          :highlight-current="true"
          :expand-on-click-node="false"
          @node-click="treeClick"
        >
          <span slot-scope="scope" class="custom-tree-node">
            <div class="label">
              <span class="name">{{ scope.data.categoryName }}</span>
              <div v-show="scope.data.categoryId == show.categoryId">
                <el-dropdown trigger="click" @command="handleCommand">
                  <i class="el-icon-s-tools"></i>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="1">新增同级类型</el-dropdown-item>
                    <el-dropdown-item command="2">编辑类型</el-dropdown-item>
                    <el-dropdown-item command="3">删除类型</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
          </span>
        </el-tree>
      </div>
      <div class="right">
        <page-header :msg-type-cate="msgTypeCate" :push-way-cate="pushWayCate" @search="search" />
        <div class="table">
          <div class="btn">
            <el-button
              v-if="powerList.find((e) => e.nodeCode == 'YY_MARKET_ADD/EDITMSGTEMPLATE')"
              icon="el-icon-plus"
              type="primary"
              size="small"
              @click="add"
              >新增</el-button
            >
            <el-button
              :disabled="multipleSelection.length == 0"
              size="small"
              @click="changeSwitch('批量', 0)"
              >启用</el-button
            >
            <el-button
              :disabled="multipleSelection.length == 0"
              size="small"
              @click="changeSwitch('批量', 1)"
              >禁用</el-button
            >
          </div>
          <Table
            ref="table"
            :table-data="tableData"
            :form="form"
            :total="total"
            :loading="loading"
            @handleSelectionChange="handleSelectionChange"
            @edit="edit"
            @detail="detail"
            @changeSwitch="changeSwitch"
          />
          <Pages
            :current-page="form.pageNumber"
            :total="total"
            @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange"
          />
        </div>
      </div>
    </div>
    <Edit
      v-if="isAdd"
      :msg-type-cate="msgTypeCate"
      :push-way-cate="pushWayCate"
      :cate-list="data"
      :row="row"
      @ok="ok"
    />
    <Detail v-if="showDetail" :row="row" @back="showDetail = false" @edit="edit" />
    <el-dialog
      :title="type == 1 ? '新增类型' : '编辑类型'"
      :visible.sync="scenVisible"
      width="500px"
      :before-close="cateCancel"
    >
      <el-form
        ref="cateForm"
        :model="cateForm"
        :rules="cateRules"
        label-width="120px"
        class="demo-ruleForm"
        size="small"
      >
        <el-form-item label="类型名称" prop="categoryName">
          <el-input
            v-model="cateForm.categoryName"
            size="small"
            style="width: 260px"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="排序数字">
          <el-input
            v-model="cateForm.sortNum"
            size="small"
            style="width: 260px"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="cateCancel">取 消</el-button>
        <el-button :loading="cateLoading" size="small" type="primary" @click="cateOk"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import pageHeader from './header'
import Table from './table'
import Pages from '@/components/pages'
import Edit from './edit'
import Detail from './detail'
import {
  messageTemplateGetList,
  messageTemplateCategory,
  queryChildsById,
  messageTemplateCategoryDel,
  messageTemplateCategorySave,
  messageTemplatebathchIdse,
} from '@/api'

export default {
  components: {
    pageHeader,
    Table,
    Pages,
    Edit,
    Detail,
  },
  data() {
    return {
      scenVisible: false,
      tableData: [],
      total: 0,
      multipleSelection: [],
      isAdd: false,
      data: [],
      defaultProps: {
        children: 'children',
        label: 'categoryName',
      },
      row: {},
      treeLoading: false,
      showDetail: false,
      form: {
        data: {},
        pageNumber: 1,
        pageSize: 10,
      },
      show: {},
      loading: false,
      msgTypeCate: [],
      pushWayCate: [],
      cateForm: {},
      cateLoading: false,
      cateRules: {
        categoryName: [{ required: true, message: '请输入类型名称', trigger: 'change' }],
      },
      type: '',
    }
  },
  computed: {
    powerList: function () {
      return this.$store.getters.getPowerList
    },
  },
  mounted() {
    this.getTableData()
    this.messageTemplateCategory()
    this.queryChildsById()
  },

  methods: {
    changeSwitch(row, e) {
      console.log(row)
      const ids = []
      let tip = ''
      if (row === '批量') {
        tip = `确认${e === 1 ? '禁用' : '启用'}所选的${this.multipleSelection.length}条模板数据?`
        this.multipleSelection.forEach((e) => {
          ids.push(e.templateId)
        })
      } else {
        tip = `确认${row.isDisable === 0 ? '禁用' : '启用'}该模板数据?`
        ids.push(row.templateId)
      }
      this.$confirm(tip, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          messageTemplatebathchIdse({
            data: {
              templateIds: [...ids],
              isDisable: e || (row.isDisable === 0 ? 1 : 0),
            },
          }).then((res) => {
            this.getTableData()
            this.$message({
              type: 'success',
              message: '操作成功!',
            })
          })
        })
        .catch(() => {})
    },
    cateOk() {
      this.$refs.cateForm.validate((valid) => {
        if (valid) {
          this.cateLoading = true
          this.cateForm.parentId = this.show.parentId
          if (this.type === 2) {
            this.cateForm.categoryId = this.show.categoryId
          }
          messageTemplateCategorySave({ data: this.cateForm })
            .then((res) => {
              this.cateForm = {}
              this.$refs.cateForm.resetFields()
              this.cateLoading = false
              this.scenVisible = false
              this.show = {}
              this.messageTemplateCategory()
              this.$message({
                message: '保存成功',
                type: 'success',
              })
            })
            .catch(() => {
              this.cateLoading = false
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    cateCancel() {
      this.cateForm = {}
      this.$refs.cateForm.resetFields()
      this.scenVisible = false
    },
    treeClick(data) {
      this.show = data
      this.form.data.categoryId = data.categoryId
      this.getTableData()
    },
    handleCommand(command, data) {
      if (command === 3) {
        this.$confirm('确认删除该条数据?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            messageTemplateCategoryDel({
              data: {
                categoryId: this.show.categoryId,
              },
            }).then((res) => {
              this.messageTemplateCategory()
              this.$message({
                type: 'success',
                message: '删除成功!',
              })
            })
          })
          .catch(() => {})
      } else {
        this.type = command
        this.scenVisible = true
        if (command === 2) {
          this.$set(this.cateForm, 'categoryName', this.show.categoryName)
          this.$set(this.cateForm, 'sortNum', this.show.sortNum)
        }
      }
    },
    queryChildsById() {
      queryChildsById({
        data: { key: 'message_type' },
      }).then((res) => {
        this.msgTypeCate = res.data.childs
      })
      queryChildsById({
        data: { key: 'message_push_type' },
      }).then((res) => {
        this.pushWayCate = res.data.childs
      })
    },
    messageTemplateCategory() {
      messageTemplateCategory({ data: {} }).then((res) => {
        this.data = res.data
      })
    },
    search(data) {
      this.form.pageNumber = 1
      this.form.data = { ...data }
      if (JSON.stringify(data) === '{}') {
        this.$refs.tree.setCurrentKey(null)
        this.show = {}
      }
      this.getTableData()
    },
    getTableData() {
      this.loading = true
      messageTemplateGetList(this.form).then((res) => {
        this.loading = false
        this.tableData = res.data.content
        this.total = Number(res.data.total)
      })
    },
    handleNodeClick(data) {
      console.log(data)
      this.form.categoryId = data.categoryId
      this.getTableData()
    },
    edit(row) {
      this.row = { ...row }
      this.showDetail = false
      this.isAdd = true
    },
    detail(row) {
      this.row = { ...row }
      this.row.flag = 'detail'
      this.showDetail = true
    },
    add() {
      this.row = {}
      this.isAdd = true
    },
    ok(e) {
      this.isAdd = false
      if (e === 1) {
        this.getTableData()
      }
      if (e.flag === 'detail') {
        this.showDetail = true
      }
    },
    cancel() {
      this.ruleForm = {}
      this.$refs.ruleForm.resetFields()
      this.dialogFormVisible = false
    },
    toggleSelection(rows) {
      this.$refs.table.toggleSelection(rows)
    },
    handleSizeChange(val) {
      this.form.pageSize = val
      this.form.pageNumber = 1
      this.getTableData()
    },
    handleCurrentChange(val) {
      this.form.pageNumber = val
      this.getTableData()
    },
    handleSelectionChange(val) {
      console.log('当前选择', val)
      this.multipleSelection = val
    },
    exportTable() {},
  },
}
</script>
<style lang="less" scoped></style>
