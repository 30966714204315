<template>
  <div class="header">
    <el-form :inline="true" :model="form" class="demo-form-inline">
      <el-form-item label="推送方式">
        <el-select v-model="form.channelId" size="small" style="width: 160px" placeholder="请选择">
          <el-option label="全部" value=""> </el-option>
          <el-option
            v-for="(n, i) in pushWayCate"
            :key="i"
            :label="n.dictionaryName"
            :value="n.dictionaryValue"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="消息类型">
        <el-select
          v-model="form.messageType"
          size="small"
          style="width: 160px"
          placeholder="请选择"
        >
          <el-option label="全部" value=""> </el-option>
          <el-option
            v-for="(n, i) in msgTypeCate"
            :key="i"
            :label="n.dictionaryName"
            :value="n.dictionaryValue"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="模板名称">
        <el-input
          v-model="form.templateName"
          size="small"
          style="width: 160px"
          placeholder="请输入"
        ></el-input>
      </el-form-item>

      <el-form-item>
        <el-link type="primary" style="margin-right: 10px" @click="checkFold">{{
          fold ? '收起' : '展开'
        }}</el-link>
        <el-button type="primary" size="small" @click="onSubmit">查询</el-button>
        <el-button type="primary" size="small" @click="reset">重置</el-button>
      </el-form-item>
      <template>
        <div v-show="fold" class="filterForm" :class="fold ? 'fold' : 'unfold'">
          <el-form-item label="启用状态">
            <el-select
              v-model="form.isDisable"
              size="small"
              style="width: 160px"
              placeholder="请选择"
            >
              <el-option label="全部" value=""> </el-option>
              <el-option label="启用" value="0"> </el-option>
              <el-option label="停用" value="1"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="模板ID">
            <el-input
              v-model="form.templateId"
              size="small"
              style="width: 160px"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="模板内容">
            <el-input
              v-model="form.templateContent"
              size="small"
              style="width: 160px"
              placeholder="请输入"
            ></el-input>
          </el-form-item>

          <el-form-item label="最后修改时间">
            <el-date-picker
              v-model="form.time"
              size="small"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              style="width: 340px"
            >
            </el-date-picker>
          </el-form-item>
        </div>
      </template>
    </el-form>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'PageHeader',
  components: {},
  props: {
    pushWayCate: {
      type: Array,
      default: () => [],
    },
    msgTypeCate: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      form: {},
      fold: false,
    }
  },
  methods: {
    onSubmit() {
      const data = { ...this.form }
      if (data.time) {
        data.updateTimeStart = moment(data.time[0]).format('YYYY-MM-DD HH:mm:ss')
        data.updateTimeEnd = moment(data.time[1]).format('YYYY-MM-DD HH:mm:ss')
      }
      delete data.time
      this.$emit('search', data)
    },
    reset() {
      this.form = {}
      this.$emit('search', this.form)
    },

    checkFold() {
      this.fold = !this.fold
    },
  },
}
</script>
<style lang="less" scoped></style>
