<template>
  <div class="box">
    <div class="title">
      <span>{{ JSON.stringify(row) != '{}' ? '编辑' : '新增' }}消息模板</span>
      <span class="right"><span style="color: red">*</span>为必填</span>
    </div>
    <div v-loading="loading" class="content">
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="136px"
        class="demo-ruleForm"
        size="small"
      >
        <el-form-item label="模板类型" prop="categoryId">
          <el-select v-model="ruleForm.categoryId" placeholder="请选择">
            <el-option
              v-for="(item, i) in cateList"
              :key="i"
              :label="item.categoryName"
              :value="item.categoryId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="模板名称" prop="templateName">
          <el-input
            v-model="ruleForm.templateName"
            size="small"
            style="width: 215px"
            maxlength="30"
            placeholder="请输入（30字内）"
          ></el-input>
        </el-form-item>
        <el-form-item label="消息类型" prop="messageType">
          <el-select
            v-model="ruleForm.messageType"
            placeholder="请选择"
            @change="messageTypeChange"
          >
            <el-option
              v-for="(n, i) in msgTypeCate"
              :key="i"
              :label="n.dictionaryName"
              :value="n.dictionaryValue"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="模板内容" prop="templateContent">
          <el-input
            v-model="ruleForm.templateContent"
            :disabled="ruleForm.messageType == 0"
            style="width: 300px"
            type="textarea"
            :rows="8"
            maxlength="500"
            show-word-limit
            placeholder="请输入模板内容"
          >
          </el-input>
        </el-form-item>

        <el-form-item label="服务场景描述" prop="businessScene">
          <el-input
            v-model="ruleForm.businessScene"
            style="width: 300px"
            type="textarea"
            :rows="8"
            maxlength="100"
            show-word-limit
            placeholder="请描述您的服务场景（业务使用场景）公众号消息服务场景描述请控制在15字"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="推送方式" prop="channelId">
          <el-radio-group v-model="ruleForm.channelId">
            <!-- <el-radio
              v-for="(n, i) in pushWayCate"
              :key="i"
              :label="n.dictionaryValue"
              >{{ n.dictionaryName }}</el-radio
            > -->
            <el-radio label="1">短信</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          v-if="ruleForm.channelId == 2 || ruleForm.channelId == 1"
          label="推送通道"
          prop="subChannelId"
        >
          <el-select v-model="ruleForm.subChannelId" multiple placeholder="请选择">
            <el-option
              v-for="(n, i) in pushChannelCate"
              :key="i"
              :label="n.dictionaryName"
              :value="n.dictionaryValue"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="ruleForm.channelId == 2"
          label="推送通道模板ID"
          prop="subChannelTemplateId"
        >
          <el-input
            v-model="ruleForm.subChannelTemplateId"
            size="small"
            style="width: 215px"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="ruleForm.channelId == 2"
          label="关键词顺序组合"
          prop="templateParameter"
        >
          <el-input
            v-model="ruleForm.templateParameter"
            style="width: 300px"
            type="textarea"
            :rows="8"
            maxlength="500"
            placeholder="请输入关键词顺序组合，关键词顺序可以自由搭配（例如 [3,5,4] 或 [4,5,3]），最多支持5个，最少2个关键词组合"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="启用状态" prop="isDisable">
          <el-select v-model="ruleForm.isDisable" placeholder="请选择">
            <el-option label="启用" value="0"></el-option>
            <el-option label="禁用" value="1"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="footer">
        <el-button size="small" @click="cancel">取消</el-button>
        <el-button :loading="btnLoading" size="small" type="primary" @click="ok">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { queryChildsById, saveTemplate, detailByIds } from '@/api'
export default {
  props: {
    row: {
      type: Object,
      default: () => {},
    },
    pushWayCate: {
      type: Array,
      default: () => [],
    },
    msgTypeCate: {
      type: Array,
      default: () => [],
    },
    cateList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      ruleForm: {
        channelId: '1',
        subChannelId: [],
      },

      pushChannelCate: [],
      btnLoading: false,
      loading: false,
      rules: {
        categoryId: [{ required: true, message: '请选择', trigger: 'change' }],
        templateName: [{ required: true, message: '请输入', trigger: 'blur' }],
        templateContent: [{ required: true, message: '请输入', trigger: 'blur' }],
        messageType: [{ required: true, message: '请选择', trigger: 'change' }],
        businessScene: [{ required: true, message: '请输入', trigger: 'blur' }],
        channelId: [{ required: true, message: '请选择', trigger: 'change' }],
        subChannelId: [{ required: true, message: '请选择', trigger: 'change' }],
        subChannelTemplateId: [{ required: true, message: '请选择', trigger: 'change' }],
        templateParameter: [{ required: true, message: '请选择', trigger: 'change' }],
        isDisable: [{ required: true, message: '请选择', trigger: 'change' }],
      },
    }
  },
  mounted() {
    this.queryChildsById()
    if (JSON.stringify(this.row) !== '{}') {
      this.detailByIds()
    }
  },
  methods: {
    messageTypeChange(e) {
      if (e === 0) {
        // eslint-disable-next-line no-template-curly-in-string
        this.ruleForm.templateContent = '${code}'
      } else {
        this.ruleForm.templateContent = ''
      }
    },
    detailByIds() {
      this.loading = true
      detailByIds({
        data: {
          templateIds: [this.row.templateId],
        },
      }).then((res) => {
        this.loading = false
        const data = res.data[0]
        data.isDisable = data.isDisable + ''
        data.channelId = data.channelId + ''
        data.messageType = data.messageType + ''
        data.subChannelId = []
        if (data.messageTemplateChannelResDto) {
          data.messageTemplateChannelResDto.forEach((e) => {
            data.subChannelId.push(e.subChannelId + '')
          })
        }

        this.ruleForm = {
          ...data,
          subChannelTemplateId: data.messageTemplateChannelResDto
            ? data.messageTemplateChannelResDto[0].subChannelTemplateId
            : '',
        }
      })
    },
    queryChildsById() {
      queryChildsById({
        data: { key: 'push_message_channel' },
      }).then((res) => {
        this.pushChannelCate = res.data.childs
      })
    },
    ok() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.btnLoading = true
          delete this.ruleForm.messageTemplateChannelResDto
          saveTemplate({
            data: {
              ...this.ruleForm,
            },
          })
            .then((res) => {
              this.btnLoading = false
              this.$message({
                message: '保存成功',
                type: 'success',
              })
              this.$emit('ok', 1)
            })
            .catch(() => {
              this.btnLoading = false
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    cancel() {
      this.$emit('ok', this.row)
    },
  },
}
</script>

<style lang="less" scoped>
.box {
  border: 1px solid #eee;
  background-color: #fff;
}
.content {
  padding: 20px;
  .footer {
    text-align: center;
  }
}
.title {
  padding: 10px 50px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  .right {
    font-size: 14px;
    color: #999;
  }
}
</style>
