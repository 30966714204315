<template>
  <el-table
    ref="multipleTable"
    v-loading="loading"
    :data="tableData"
    border
    style="width: 100%"
    @selection-change="handleSelectionChange"
  >
    <el-table-column type="selection" width="55"> </el-table-column>
    <el-table-column prop="date" label="序号" width="80">
      <template slot-scope="scope">
        <span>{{ total - scope.$index - (form.pageNumber - 1) * form.pageSize }}</span>
      </template>
    </el-table-column>
    <el-table-column prop="templateId" label="模板ID" min-width="80"> </el-table-column>
    <el-table-column prop="templateName" label="模板名称" min-width="150"> </el-table-column>
    <el-table-column prop="templateContent" label="模板内容" min-width="200"> </el-table-column>
    <el-table-column prop="messageTypeName" label="消息类型" min-width="150"> </el-table-column>
    <el-table-column prop="channelName" label="推送方式" min-width="150"> </el-table-column>
    <el-table-column prop="isDisable" label="启用状态" min-width="150">
      <template slot-scope="scope">
        <el-switch
          v-model="scope.row.isDisable"
          :active-value="0"
          :inactive-value="1"
          disabled
          @click.native.prevent="changeSwitch(scope.row)"
        >
        </el-switch>
        <span style="margin-left: 4px">{{ scope.row.isDisable == 0 ? '启用' : '禁用' }}</span>
      </template>
    </el-table-column>
    <el-table-column prop="updateTime" label="最后修改时间" min-width="180"> </el-table-column>
    <el-table-column prop="address" label="操作" min-width="150">
      <template slot-scope="scope">
        <el-button
          v-if="powerList.find((e) => e.nodeCode == 'YY_MARKET_ADD/EDITMSGTEMPLATE')"
          type="text"
          size="small"
          @click="edit(scope.row)"
          >编辑</el-button
        >
        <el-button
          v-if="powerList.find((e) => e.nodeCode == 'YY_MARKET_MSGTEMPLATEDETAIL')"
          type="text"
          size="small"
          @click="detail(scope.row)"
          >详情</el-button
        >
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      default: () => {},
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      selectData: [],
    }
  },
  computed: {
    powerList: function () {
      return this.$store.getters.getPowerList
    },
  },
  methods: {
    getArrDifference(arr1, arr2) {
      return arr1.concat(arr2).filter(function (v, i, arr) {
        return arr.indexOf(v) === arr.lastIndexOf(v)
      })
    },
    toggleSelection(rows) {
      if (rows) {
        this.$refs.multipleTable.toggleAllSelection()
      } else {
        this.$refs.multipleTable.clearSelection()
        // if (this.selectData.length > 0) {
        //   console.log(this.getArrDifference(this.tableData, this.selectData));
        //   this.getArrDifference(this.tableData, this.selectData).forEach(
        //     (row) => {
        //       this.$refs.multipleTable.toggleRowSelection(row, true);
        //     }
        //   );
        //   this.selectData.forEach((row) => {
        //     this.$refs.multipleTable.toggleRowSelection(row, false);
        //   });
        // }
      }
    },
    changeSwitch(row) {
      this.$emit('changeSwitch', row)
    },
    edit(row) {
      this.$emit('edit', row)
    },
    detail(row) {
      this.$emit('detail', row)
    },
    handleSelectionChange(val) {
      this.selectData = val
      this.$emit('handleSelectionChange', val)
    },
  },
}
</script>

<style lang="less" scoped>
::v-deep .cell {
  display: flex;
  align-items: center;
}
.el-switch.is-disabled {
  opacity: 1;
}
::v-deep .el-switch.is-disabled .el-switch__core,
.el-switch.is-disabled .el-switch__label {
  cursor: pointer !important;
}
</style>
